import { makeStyles } from '@mui/styles'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import tileApi from 'apis/disApi/tileApi'
import {
    cancelLoadNextRecordsAction,
    getAllProjects,
    getProjectForms,
    getViewTabViewRecords,
    loadNextRecordsQuery,
    loadRecordsQuery,
} from 'components/data/dataServices'
import FormsTile from 'components/pages/forms/FormsTile'
import { EnvironmentProvider } from 'custom-components/context/EnvironmentContext'
import { IconThemeProvider } from 'custom-components/context/IconThemesContext'
import { off, onValue, ref } from 'firebase/database'
import _, { cloneDeep, isEmpty, isEqual, uniqBy } from 'lodash'
import moment from 'moment/moment'
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    useLayoutEffect,
    useReducer,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ENV_ACTIONS } from 'reducers/environmentReducer'
import { FORM_ACTIONS } from 'reducers/formsReducer'
import LoadingSpinner from '../../../../../custom-components/LoadingSpinner'
import { Button } from '@mui/material'
import database from '../../../../../firebase-config'
import { getSortModel, isJson, logErrorMessage } from '../../../../../utils/functions/helpers'
import {
    getSelectedGridRecord,
    getSelectedGridRecordWithoutFilter,
    parsedDataGridColumns,
} from '../../../../data/dataHelpers'
import { getViewRecords } from '../../../../data/dataServices'
import { getViewClientFilters, getSavedFilterList } from '../../../../data/dataHelpers'
import TileWrapper from '../../components/TileWrapper'
import { useTileDashboard } from '../../dashboard/Dashboard'
import useDashboardQuery from '../../hooks/useDashboardQuery'
import { tileKeys } from '../../hooks/useTileQuery'
import DataTable from './DataTable'
import DatagridSettingsDialog from './DatagridSettingsDialog'
import { useIsMounted } from '../../hooks/useIsMounted'
import { TileSettingsDialog, INIT_SETTINGS } from './wizard-settings-dialog'
import { getAutoUpdateFilters } from '../../helpers'
import { useSearchParams } from 'utils/hooks/useSearchParams'
import {
    MOBILE_UNIT_VIEW_TYPE,
    PROJECT_FORM_VIEW_TYPE,
} from 'components/data/datagrid/CreatViewComponents/ViewDialogUtils'
import { useRecordQuery } from '../utils/tileRecordQuery'
import { sleep } from 'utils/functions/helpers'

const useStyles = makeStyles(() => ({
    formSidePanel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: '100%',
        backgroundColor: '#ffffff',
    },
}))

const DatagridTile = ({ tile }) => {
    const { environment, formsModule } = useSelector((state) => state)
    const iconTheme = environment.theme.icons
    const dispatch = useDispatch()
    const classes = useStyles(iconTheme)

    const [error, setError] = useState(null)
    const queryClient = useQueryClient()
    const { id: dashboardKey } = useParams()
    const { menuKey: menuKeyInUrl } = useSearchParams()

    const [settingsOpen, setSettingsOpen] = useState(false)

    const [recordFilter, setRecordFilter] = useState(null)
    const [currentRecords, setCurrentRecords] = useState(null)
    // const [isFetchingRecords, setIsFetchingRecords] = useState(false)

    const [columnsWidth, setColumnsWidth] = useState({})

    // settings dialog
    const [isOpenSettingsDialog, setIsOpenSettingsDialog] = useState(false)
    const [allDialogSettings, setAllDialogSettings] = useState(INIT_SETTINGS)

    const tileRef = useRef(null)
    // const isFetchingNextRecords = useRef(false)

    const settings = useMemo(() => {
        if (tile?.settings && isJson(tile?.settings)) {
            return JSON.parse(tile?.settings ?? '{}')
        }

        return {}
    }, [tile?.settings])

    const isMounted = useIsMounted()

    const {
        projectKey,
        formKey,
        viewKey,
        viewType,
        columnsChecked,
        otherOptionsChecked,
        tileWidth,
        linkedFields,
        filterModel: clientFilterModel,
        aggregationModel,
        rowGroupingModel,
        sortModel,
    } = useMemo(() => settings, [settings])

    useLayoutEffect(() => {
        const columnsWidthSetting = settings?.columnsWidth
        if (isEmpty(columnsWidthSetting)) return
        setColumnsWidth(columnsWidthSetting)
    }, [settings])

    useEffect(() => {
        return async () => {
            const mounted = isMounted()
            if (mounted || isEmpty(columnsWidth)) return

            // get setting from tile instead of settings
            let currentSettings = {}
            if (tile?.settings && isJson(tile?.settings)) {
                currentSettings = { ...JSON.parse(tile?.settings ?? '{}') }
            }
            if (isEmpty(currentSettings)) return
            try {
                const editedSettings = {
                    ...currentSettings,
                    columnsWidth,
                }

                if (isEqual(currentSettings, editedSettings)) return
                await updateTileMutation.mutateAsync({
                    dashboardKey,
                    tileKey: tile.key,
                    data: { settings: JSON.stringify(editedSettings) },
                    token: environment.apiToken,
                })
            } catch (error) {
                logErrorMessage(error)
            }
        }
    }, [columnsWidth])

    const { selectedFields, selectedMobileUnitNumber, setIsRemovedTile } = useTileDashboard()

    const { updateMutation: updateDashboard, dashboardKeyList } = useDashboardQuery({
        dashboardKey,
    })

    const { filterConfigs, conditions } = useMemo(
        () =>
            getAutoUpdateFilters(
                linkedFields,
                selectedFields,
                dashboardKeyList,
                true,
                gridColumns || []
            ),
        [linkedFields, selectedFields, gridColumns]
    )

    const hasUseRangeDateTimeKey = useMemo(() => {
        if (isEmpty(linkedFields)) return false
        const a = dashboardKeyList.filter((item) => !!linkedFields[item.id] && ['Master_DateTime_Start', 'Master_DateTime_End'].includes(item.key))
        return !isEmpty(a)
    }, [linkedFields, dashboardKeyList])

    const masterDateTimeOperator = useMemo(() => {
        if (!isEmpty(conditions)) {
            const allConditions = []
            for (const key in conditions) {
                const value = conditions[key]
                const condition = {
                    ...value,
                    target: key,
                    join: 'AND',
                }
                allConditions.push(condition)
            }
            return allConditions
        }
        return []
    }, [conditions])

    useEffect(() => {
        ; (async () => {
            try {
                if (isEmpty(data?.clientFilter) || isEmpty(masterDateTimeOperator)) return
                //res.data.queries[0]?.filter?.conditions ?? []

                const clonedFilter = _.cloneDeep(data?.clientFilter)
                const checkedConditions = clonedFilter.conditions.map((condition, index) => {
                    const keyToCheck = index === 0 ? 'Master_DateTime' : index === 1 ? 'Master_Text' : ''
                    if (!keyToCheck) return condition

                    const newCondition = masterDateTimeOperator.find(
                        (operator) => operator.keyData === keyToCheck
                    )
                    if (!newCondition) return condition

                    return newCondition
                })
                clonedFilter.conditions = checkedConditions
                setRecordFilter(clonedFilter)
                // await fetchRecords(clonedFilter)
            } catch (error) {
                console.log(error)
            }
        })()
    }, [masterDateTimeOperator, data?.clientFilter, hasUseRangeDateTimeKey])

    const updateTileMutation = useMutation(tileApi.update, {
        onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
    })

    const isShowForm = useMemo(() => (tile?.type === 'FormList' ? true : false), [tile?.type])

    const [gridColumns, setGridColumns] = useState(null)

    const { data, isFetching, isLoading, isPreviousData, refetch, isRefetching, isSuccess } =
        useQuery({
            queryKey: [
                'data-table',
                tile.key,
                projectKey,
                formKey,
                viewKey,
                columnsChecked,
                otherOptionsChecked,
                dashboardKeyList,
                linkedFields,
                viewType,
                // filterModel,
            ],
            queryFn: async () => {
                // get current viewData
                let res
                try {
                    res = await getViewTabViewRecords({
                        viewKey,
                        token: environment.apiToken,
                        shouldRemoveViewSession: true,
                        viewType,
                    })
                } catch (error) {
                    logErrorMessage(error)
                    return {}
                }

                if (!res?.data?.columns) {
                    return {}
                }

                const fields = res.data.columns
                const conditions = res.data.queries[0]?.filter?.conditions ?? []
                let allOfFilter = {}

                const clientFilter = res?.data?.clientFilter ? JSON.parse(res?.data?.clientFilter) : []
                //: { items: [] }

                const savedFilterList = getSavedFilterList(clientFilter)
                const currentFiltersWithUserFilter = getViewClientFilters(
                    savedFilterList,
                    environment?.userCurrent?.dataGroupKey
                )

                const wizardFilters = {
                    viewClientFilters: [],
                    userClientFilters: [],
                }

                const filters = savedFilterList.reduce((prev, current) => {
                    if (current.dataGroupKey) {
                        prev.userClientFilters.push(current)
                    } else {
                        prev.viewClientFilters.push(current)
                    }

                    return prev
                }, wizardFilters)

                allOfFilter = {
                    ...allOfFilter,
                    items: currentFiltersWithUserFilter?.items
                        ? currentFiltersWithUserFilter.items
                        : currentFiltersWithUserFilter,
                    conditions,
                }

                const primaryKeyIdList = dashboardKeyList
                    .filter((item) => item.tileName === tile.i)
                    .map((item) => item.key)
                const linkedIdList = Object.values(linkedFields ?? {})
                const modelFilters = clientFilterModel?.items ? clientFilterModel.items : clientFilterModel
                const columnsFromViewPage = modelFilters?.map((item) => item.field) ?? []

                let newColumns = []

                if (tile?.isDuplicate) {
                    newColumns = fields
                } else {
                    // Help hide primary key but it still filter table
                    const combineAllColumns = _.uniq([
                        ...columnsChecked,
                        ...primaryKeyIdList,
                        ...linkedIdList,
                        ...columnsFromViewPage,
                    ])

                    // keep column order
                    for (const field of fields) {
                        const isDisplayed = combineAllColumns.some((column) => field.name === column)
                        if (!isDisplayed) continue
                        newColumns.push(field)
                    }
                }
                let viewResponse
                if (!formKey && !projectKey) {
                    viewResponse = res
                } else {
                    viewResponse = await getViewRecords(
                        formKey,
                        projectKey,
                        false,
                        environment.apiToken,
                        viewType
                    )
                }

                //const records = await getRecords({ clientFilter: allOfFilter, viewKey })
                // let records = []
                // try {
                // 	records = await getRecords({ clientFilter: allOfFilter, viewKey })
                // } catch (error) {
                // 	logErrorMessage(error)
                // }
                const sortModel = getSortModel(fields, res?.data?.sorts)
                const colorConditions = JSON.parse(res?.data?.colorConditions || '{}')

                // const gridColumns = parsedDataGridColumns(environment, records, newColumns)

                const isEnableExport = otherOptionsChecked?.includes('enableExport')
                const isEnableActiveUpdate = otherOptionsChecked?.includes('enableActiveUpdate')
                const isAllowViewEdit =
                    res.data === MOBILE_UNIT_VIEW_TYPE
                        ? false
                        : otherOptionsChecked?.includes('allowViewEdit')
                const isAllowDispatch = otherOptionsChecked?.includes('allowDispatch')
                const isShowHeaders = !otherOptionsChecked?.includes('hideHeaders')
                const isShowTotalRows = !otherOptionsChecked?.includes('hideTotalRows')

                if (isEnableExport) {
                    return {
                        clientFilter: allOfFilter,
                        userClientFilters: filters?.userClientFilters,
                        // columns: gridColumns,
                        viewData: viewResponse?.data ?? {},
                        haveExport: true,
                        haveActiveUpdate: isEnableActiveUpdate,
                        allowEdit: isAllowViewEdit,
                        allowDispatch: isAllowDispatch,
                        isShowHeaders,
                        isShowTotalRows,
                        columnCheckList: fields,
                        columnsSketch: newColumns,
                        sortModel,
                        colorConditions,
                        queryView: res.data,
                    }
                }

                if (isAllowViewEdit) {
                    return {
                        clientFilter: allOfFilter,
                        userClientFilters: filters?.userClientFilters,
                        // columns: gridColumns,
                        viewData: viewResponse?.data ?? {},
                        haveExport: isEnableExport,
                        haveActiveUpdate: isEnableActiveUpdate,
                        allowEdit: isAllowViewEdit,
                        allowDispatch: isAllowDispatch,
                        isShowHeaders,
                        isShowTotalRows,
                        columnCheckList: fields,
                        columnsSketch: newColumns,
                        sortModel,
                        colorConditions,
                        queryView: res.data,
                    }
                }

                return {
                    clientFilter: allOfFilter,
                    userClientFilters: filters?.userClientFilters,
                    // columns: gridColumns,
                    viewData: viewResponse?.data ?? {},
                    haveExport: false,
                    haveActiveUpdate: isEnableActiveUpdate,
                    allowEdit: isAllowViewEdit,
                    allowDispatch: isAllowDispatch,
                    isShowHeaders,
                    isShowTotalRows,
                    columnCheckList: fields,
                    columnsSketch: newColumns,
                    sortModel,
                    colorConditions,
                    queryView: res.data,
                }
            },
            enabled:
                Boolean(tile.key) &&
                (viewType === PROJECT_FORM_VIEW_TYPE ? Boolean(projectKey) && Boolean(formKey) : true) &&
                //Boolean(projectKey) &&
                Boolean(viewKey) &&
                //Boolean(formKey) &&
                columnsChecked?.length !== 0,
        })

    useEffect(() => {
        if (_.isEqual(recordFilter, data?.clientFilter)) return

        setRecordFilter(data?.clientFilter)
    }, [data?.clientFilter])

    const { records, recordsLoading, hasMoreRecords, refetchRecords, onCancelLoadingRecords } = useRecordQuery({
        viewKey,
        viewType,
        clientFilter: recordFilter
    })

    useEffect(() => {
        if (recordsLoading) return
        if (isEqual(records, currentRecords)) return
        setCurrentRecords(records)
        const gridColumns = parsedDataGridColumns(environment, records, data?.columnsSketch)
        setGridColumns(gridColumns)
    }, [recordsLoading, records, JSON.stringify(data?.columnsSketch)])

    const tileLoading = useMemo(() => {
        return isRefetching || isPreviousData || isFetching || isLoading
    }, [isRefetching, isPreviousData, isFetching, isLoading])

    const currentRows = useMemo(() => {
        if (!currentRecords?.length || !data?.columnsSketch?.length || !data?.columnCheckList?.length)
            return []

        let tileSelectedMobileUnitKey = ''
        if (!isEmpty(selectedMobileUnitNumber)) {
            tileSelectedMobileUnitKey = selectedMobileUnitNumber[tile?.key]?.number
        }
        const filteredRecords = isEmpty(tileSelectedMobileUnitKey)
            ? currentRecords
            : currentRecords.filter((record) => record.meta?.source === tileSelectedMobileUnitKey)

        return getSelectedGridRecord({
            records: filteredRecords,
            columns: data?.columnsSketch,
            environment,
            columnCheckList: data?.columnCheckList,
        })
    }, [
        currentRecords,
        selectedMobileUnitNumber,
        JSON.stringify(data?.columnsSketch),
        JSON.stringify(data?.columnCheckList),
    ])

    const defaultRows = useMemo(() => {
        if (!currentRecords?.length || !data?.columnCheckList?.length) return []

        return getSelectedGridRecordWithoutFilter({
            records: currentRecords,
            columns: data?.columnCheckList,
            columnCheckList: data?.columnCheckList,
        })
    }, [currentRecords, JSON.stringify(data?.columnCheckList)])

    useEffect(() => {
        if (data?.haveActiveUpdate) {
            let serverOffset = 0
            const offsetRef = ref(database, ".info/serverTimeOffset")

            const unsubscribeOffset = onValue(offsetRef, (snapshot) => {
                serverOffset = snapshot.val() || 0; // Offset in milliseconds
            })

            if (viewType === PROJECT_FORM_VIEW_TYPE && formKey && projectKey) {
                const formRef = ref(database, 'formSessions/' + projectKey + '/' + formKey)
                const unsubscribe = onValue(formRef, (snapshot) => {
                    // Process data when there is a change
                    refetchRecords()
                })

                // Returns the off function to turn off event listening when the component unmounts
                return () => {
                    off(formRef)
                    unsubscribeOffset()
                    unsubscribe()
                }
            } else if (viewType === MOBILE_UNIT_VIEW_TYPE) {
                const customerKey = environment.account.key
                if (!customerKey) return

                const mobileUserRef = ref(database, `userSessions/mobileUserUpdate/customer/${customerKey}`)

                const mobileUserUnsubscribe = onValue(mobileUserRef, (snapshot) => {
                    // const data = snapshot.val()
                    // const currentDateTime = moment().format('MM/DD/YYYY HH:mm:ss')

                    sleep(2000).then(() => {
                        refetchRecords()
                    })
                })

                return () => {
                    off(mobileUserRef)
                    unsubscribeOffset()
                    mobileUserUnsubscribe()
                }
            } else {
                const customerKey = environment.account.key
                if (!customerKey) return
                const customerCountRef = ref(database, `userSessions/customerKey/${customerKey}`)
                const viewRef = ref(database, `userSessions/viewActivity/customerKey/${customerKey}`)
                const timeSheetRef = ref(database, `userSessions/timeSheet/customerKey/${customerKey}`)
                const geoTabRef = ref(database, `userSessions/geotab/customerKey/${customerKey}`)

                const customerUnsubscribe = onValue(customerCountRef, (snapshot) => {
                    // const data = snapshot.val()
                    // const currentDateTime = moment().add(serverOffset, 'milliseconds').format('MM/DD/YYYY HH:mm:ss')

                    // if (data && areDatesEqual(data?.Updated, currentDateTime, 'MM/DD/YYYY HH:mm:ss')) {
                    refetchRecords()
                    // }
                })

                const viewUnsubscribe = onValue(viewRef, (snapshot) => {
                    refetchRecords()
                })

                const timeSheetUnsubscribe = onValue(timeSheetRef, (snapshot) => {
                    refetchRecords()
                })

                const geoTabUnsubscribe = onValue(geoTabRef, (snapshot) => {
                    refetchRecords()
                })

                // Returns the off function to turn off event listening when the component unmounts
                return () => {
                    off(customerCountRef)
                    off(viewRef)
                    off(timeSheetRef)
                    off(geoTabRef)
                    unsubscribeOffset()
                    customerUnsubscribe()
                    viewUnsubscribe()
                    timeSheetUnsubscribe()
                    geoTabUnsubscribe()
                }
            }
        }
    }, [viewType, formKey, projectKey, data?.haveActiveUpdate])

    useEffect(() => {
        if (_.isEmpty(selectedFields)) return

        const newSelectedFields = currentRows.find(
            (item) => item.recordKey === selectedFields[tile?.key]?.recordKey
        )
        const oldSelectedFields = selectedFields[tile?.key]

        if (_.isEmpty(newSelectedFields)) {
            setIsRemovedTile((prev) => ({
                ...prev,
                [tile?.key]: true,
            }))
            return
        }

        // Check if user change name in database, then remove tile
        if (oldSelectedFields?.Vehicle_Name !== newSelectedFields?.Vehicle_Name) {
            setIsRemovedTile((prev) => ({
                ...prev,
                [tile?.key]: true,
            }))
            return
        }

        setIsRemovedTile((prev) => ({
            ...prev,
            [tile?.key]: false,
        }))
    }, [currentRows, selectedFields, tile?.key])

    useEffect(() => {
        if (!isShowForm) return
        dispatch({
            type: FORM_ACTIONS.TAB_CHANGE,
            payload: FORM_ACTIONS.NAME,
        })

        if (!environment.isProjectFormsLoaded) {
            initiateLoadAllProjects()
        }
    }, [isShowForm])

    useEffect(() => {
        if (!environment.refresh) return
        dispatch({
            type: ENV_ACTIONS.CLEAR_FORMS,
        })
        initiateLoadAllProjects()
        dispatch({
            type: ENV_ACTIONS.REFRESH,
            payload: false,
        })
    }, [environment.refresh])

    const initiateLoadAllProjects = () => {
        dispatch({
            type: ENV_ACTIONS.IS_LOADING_PROJECTS,
            payload: true,
        })
        loadAllProjects()
            .then((res) => {
                const projects = _.sortBy(res.data, (project) => project.name.toLowerCase())
                initiateLoadFormsByProject(projects)
            })
            .catch((err) => {
                setError('Code ' + err.response?.data?.code + ': ' + err.response?.data?.message)
            })
            .finally(() => {
                dispatch({
                    type: ENV_ACTIONS.IS_PROJECT_FORMS_LOADED,
                    payload: true,
                })
            })
    }

    const loadAllProjects = async () => {
        let promise = await getAllProjects(environment.apiToken)
        return promise
    }

    const initiateLoadFormsByProject = (projects) => {
        let promises = []
        let newProjects = []
        for (let i = 0; i < projects.length; i++) {
            const promise = loadFormsByProject(projects[i])
                .then((res) => {
                    if (res.name.toLowerCase() !== 'lookups') {
                        newProjects[i] = { ...res }
                    }
                    dispatch({
                        type: ENV_ACTIONS.GET_PROJECTS,
                        payload: newProjects.filter((val) => val),
                    })
                })
                .catch((err) => {
                    setError('Code ' + err.response?.data?.code + ': ' + err.response?.data?.message)
                })
            promises.push(promise)
        }

        Promise.all(promises).then(() => {
            dispatch({
                type: ENV_ACTIONS.IS_LOADING_PROJECTS,
                payload: false,
            })
        })
    }

    const loadFormsByProject = (project) => {
        return getProjectForms(project.key, environment.apiToken).then((resp) => {
            let promiseObject = { key: project.key, name: project.name, forms: [] }
            if (resp?.data && _.isArray(resp.data) && resp.data.length) {
                promiseObject.forms = _.sortBy(resp.data, (project) => project.name.toLowerCase())
            }
            return promiseObject
        })
    }

    const handleOpenDialog = () => {
        setSettingsOpen(true)
    }

    const handleCloseDialog = () => {
        setSettingsOpen(false)
    }

    const handleSubmitSetting = async (data) => {
        try {
            const { primaryKeyList, ...restData } = data
            const newViewKey = data.viewKey

            if (newViewKey !== viewKey) {
                setCurrentRecords([])
            }

            const isHavePrimaryKey = Boolean(primaryKeyList)
            if (isHavePrimaryKey) {
                await updateDashboard.mutateAsync({
                    key: dashboardKey,
                    data: {
                        variables: JSON.stringify(primaryKeyList),
                    },
                    token: environment.apiToken,
                })
            }

            handleCloseDialog()
            const editedSettings = JSON.stringify({
                ...settings,
                ...restData,
                tileWidth,
                aggregationModel,
                rowGroupingModel,
                sortModel,
            })

            await updateTileMutation.mutateAsync({
                dashboardKey,
                tileKey: tile.key,
                data: { settings: editedSettings },
                token: environment.apiToken,
            })
        } catch (error) {
            logErrorMessage(error)
        }
    }

    const handleResizeTileWidth = async (width) => {
        try {
            const editedSettings = JSON.stringify({
                ...settings,

                tileWidth: width,
            })

            await updateTileMutation.mutateAsync({
                dashboardKey,
                tileKey: tile.key,
                data: { settings: editedSettings },
                token: environment.apiToken,
            })
        } catch (error) {
            logErrorMessage(error)
        }
    }

    const handleHeaderFilterModel = useCallback(
        async (name, value) => {
            try {
                const editedSettings = JSON.stringify({
                    ...settings,

                    [name]: value,
                })

                await updateTileMutation.mutateAsync({
                    dashboardKey,
                    tileKey: tile.key,
                    data: { settings: editedSettings },
                    token: environment.apiToken,
                })
            } catch (error) {
                logErrorMessage(error)
            }
        },
        [dashboardKey, tile.key, settings]
    )

    const serverFilterModel = useMemo(() => {
        if (!isEmpty(allDialogSettings?.additionalFilters)) {
            const userSpecificFilters = data?.userClientFilters?.items
                ? data?.userClientFilters?.items?.filter((item) => !item.columnField)
                : data?.userClientFilters?.filter((item) => !item.columnField)
            const additionalFilters = allDialogSettings?.additionalFilters?.items
                ? allDialogSettings?.additionalFilters?.items?.filter((item) => !item.columnField)
                : allDialogSettings?.additionalFilters?.filter((item) => !item.columnField)

            return [...(userSpecificFilters || []), ...(additionalFilters || [])]
        }
        if (isFetching || isLoading) return

        // client filters of View - already has userSpecificFilters
        return data?.clientFilter?.items
            ? data?.clientFilter?.items?.filter((item) => !item.columnField)
            : data?.clientFilter?.filter((item) => !item.columnField)
    }, [
        isFetching,
        isLoading,
        data?.clientFilter,
        data?.userClientFilters,
        allDialogSettings?.additionalFilters,
    ])

    const sidePanel = () => (
        <div id="form-side-panel" className={classes.formSidePanel}>
            <EnvironmentProvider values={environment}>
                <FormsTile
                    projects={environment.projects}
                    loading={environment.isLoadingProjects}
                    module={formsModule}
                    env={environment}
                />
            </EnvironmentProvider>
        </div>
    )

    const WizardSettingDialog = useCallback(() => {
        if (!isOpenSettingsDialog) return null

        async function fetchRecords(allSettingDialogs) {
            try {
                // setIsFetchingRecords(true)
                const clientFilter = _.cloneDeep(data?.clientFilter)
                if (!isEmpty(allSettingDialogs?.primaryFilter)) {
                    clientFilter.conditions = allSettingDialogs?.primaryFilter
                }

                // await getRecords({ clientFilter, viewKey, keepOldRecords: false, viewType })
                setRecordFilter(clientFilter)
                // setIsFetchingRecords(false)
            } catch (error) {
                setError(error?.message)
                // setIsFetchingRecords(false)
            }
        }

        return (
            <TileSettingsDialog
                isOpen={isOpenSettingsDialog}
                setIsOpen={setIsOpenSettingsDialog}
                allDialogSettings={allDialogSettings}
                setAllDialogSettings={setAllDialogSettings}
                columns={gridColumns || []}
                viewData={data?.viewData || {}}
                clientFilter={data?.clientFilter || {}}
                userClientFilters={data?.userClientFilters || []}
                queryView={data?.queryView || {}}
                fetchRecords={fetchRecords}
                serverFilterModel={serverFilterModel}
                serverSortModel={data?.sortModel}
            />
        )
    }, [
        gridColumns,
        data?.clientFilter,
        data?.viewData,
        data?.queryView,
        data?.sortModel,
        serverFilterModel,
        isOpenSettingsDialog,
        allDialogSettings,
    ])

    async function fetchRecords(clientFilter) {
        try {
            // setIsFetchingRecords(true)
            setRecordFilter(clientFilter)
            // await getRecords({ clientFilter, viewKey, keepOldRecords: false, viewType })
            // setIsFetchingRecords(false)
        } catch (error) {
            setError(error?.message)
            // setIsFetchingRecords(false)
        }
    }

    const fetchDataFrmDoForms = async () => {
        await fetchRecords(data?.clientFilter)
    }

    return (
        <IconThemeProvider values={iconTheme}>
            <TileWrapper
                title={tile?.i}
                onSettingClick={handleOpenDialog}
                ref={tileRef}
                isExpandDialogBtn
            >
                {(tileLoading || updateTileMutation.isLoading || recordsLoading) && <LoadingSpinner />}

                <DatagridSettingsDialog
                    tileElementWidth={tileRef?.current?.clientWidth}
                    defaultTileWidth={tileWidth}
                    tile={tile}
                    dashboardKeyList={dashboardKeyList}
                    settings={settings}
                    isSubmitting={tileLoading}
                    open={settingsOpen}
                    onClose={handleCloseDialog}
                    onSubmit={handleSubmitSetting}
                    onResizeTileWidth={handleResizeTileWidth}
                    tileEditSettingsComponent={
                        <Button onClick={() => setIsOpenSettingsDialog(true)}> View filter </Button>
                    }
                    dashboardKey={dashboardKey}
                    menuKeyInUrl={menuKeyInUrl}
                />

                {data && !isShowForm && (
                    <DataTable
                        dashboardKeyList={dashboardKeyList}
                        linkedFields={linkedFields ?? {}}
                        clientFilterModel={clientFilterModel || []}
                        serverFilterModel={serverFilterModel || []}
                        aggregationModel={aggregationModel ?? {}}
                        rowGroupingModel={rowGroupingModel ?? []}
                        sortModel={sortModel || []}
                        serverSortModel={data?.sortModel || []}
                        tile={tile}
                        columns={gridColumns ?? []}
                        rows={currentRows ?? []}
                        defaultRows={defaultRows}
                        viewData={data?.viewData ?? {}}
                        tab={'tile'}
                        haveExport={data?.haveExport}
                        allowEdit={data?.allowEdit}
                        allowDispatch={data?.allowDispatch}
                        clientFilter={data?.clientFilter}
                        columnsChecked={columnsChecked}
                        onRefreshTable={refetch}
                        records={currentRecords}
                        hasMoreRecords={hasMoreRecords}
                        isShowHeaders={data?.isShowHeaders}
                        isShowTotalRows={data?.isShowTotalRows}
                        bgColorRowCondition={data?.colorConditions}
                        onHeaderFilterModel={handleHeaderFilterModel}
                        onCancelLoadingRecords={onCancelLoadingRecords}
                        columnsWidth={columnsWidth || {}}
                        setColumnsWidth={setColumnsWidth}
                        settings={settings}
                        dashboardKey={dashboardKey}
                        fetchDataFrmDoForms={fetchDataFrmDoForms}
                        fetchRecords={fetchRecords}
                        filterConfigs={filterConfigs}
                    />
                )}

                {isShowForm && <IconThemeProvider values={iconTheme}>{sidePanel()}</IconThemeProvider>}
                <WizardSettingDialog />
            </TileWrapper>
        </IconThemeProvider>
    )
}

export default DatagridTile
