import { FormControl, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { toZonedTime } from 'date-fns-tz'
import { enUS } from 'date-fns/locale'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toDayjs } from 'utils/functions/doformsDateUtil'
import { getUserTimeZone } from 'utils/functions/helpers'

function MapFilters({
	vehicleList,
	vehicleName,
	vehicleLoading,
	fromDate,
	toDate,
	disableFilters,
	onFilters,
}) {
	const { t } = useTranslation('common')
	const [locale, setLocale] = useState(enUS)
	const displayFromDate = useMemo(() => {
		return toDayjs(fromDate)
	}, [fromDate])

	const displayToDate = useMemo(() => {
		return toDayjs(toDate)
	}, [toDate])
	const handleFromDateChange = (newValue) => {
		if (newValue == null) {
			onFilters?.('fromDate', newValue)
			return
		}
		const localDate = toZonedTime(newValue.format('MM/DD/YYYY HH:mm:ss'), getUserTimeZone())

		onFilters?.('fromDate', localDate)
	}

	const handleToDateChange = (newValue) => {
		if (newValue == null) {
			onFilters?.('toDate', newValue)
			return
		}

		const localDate = toZonedTime(newValue.format('MM/DD/YYYY HH:mm:ss'), getUserTimeZone())

		onFilters?.('toDate', localDate)
	}

	return (
		<>
			{!vehicleLoading ? (
				<FormControl sx={{ minWidth: 120 }} size="small" disabled={disableFilters}>
					<InputLabel id="vehicle-select-small-label">{t('common:input.vehicleName')}</InputLabel>
					<Select
						labelId="vehicle-select-small-label"
						id="vehicle-select-small"
						value={vehicleName || ''}
						label={t('common:input.vehicleName')}
						onChange={(e) => {
							onFilters?.('vehicleName', e.target.value)
						}}
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{vehicleList?.length > 0 &&
							vehicleList.map((vehicle) => (
								<MenuItem value={vehicle.name} key={vehicle.name}>
									{vehicle.name}
								</MenuItem>
							))}
					</Select>
				</FormControl>
			) : (
				<Skeleton variant="rectangular" height={45} sx={{ minWidth: 120 }} />
			)}

			<LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
				<DatePicker
					componentsProps={{
						actionBar: {
							actions: ['clear'],
						},
					}}
					id="map-date"
					label={t('common:input.fromDate')}
					disabled={disableFilters}
					value={displayFromDate}
					maxDate={toDayjs(locale.maxDate)}
					onChange={handleFromDateChange}
					slotProps={{
						textField: {
							InputProps: {
								size: "small",
								variant: "outlined",
								error: false
							}
						}
					}}
				/>
			</LocalizationProvider>

			<LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
				<DatePicker
					componentsProps={{
						actionBar: {
							actions: ['clear'],
						},
					}}
					id="map-date"
					label={t('common:input.toDate')}
					disabled={disableFilters}
					value={displayToDate}
					maxDate={toDayjs(locale.maxDate)}
					onChange={handleToDateChange}
					slotProps={{
						textField: {
							InputProps: {
								size: "small",
								variant: "outlined",

								// Turn off the error when the user clears the date
								error: false
							}
						}
					}}
				/>
			</LocalizationProvider>
		</>
	)
}

export default MapFilters
