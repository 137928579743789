import { useMemo, useState, useEffect, memo, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isEqual } from 'lodash'

import { Typography } from '@mui/material'

import { datesValues, nonDatesValues, operatorOptions } from 'utils/functions/conditionsOptions'
import DoformsConditionsBuilder from 'components/data/datagrid/DoformsConditionsBuilder'

import { ACTIVITY_RECEIVE_TIME_KEY, VIEW } from '../../../../../constants'
import CollapseComponent from './CollapseComponent'

import { shouldPreventRender, SETTING_TYPES, WIZARD_VIEW_TYPES } from '../ViewDialogUtils'

const operatorMap = {
    EQ: '=',
    NE: '!=',
}

const DEFAULT_ACTIVE_DISPACTH_PRIMARY_COLUMN = {
    name: '@DispatchOwnerId',
    title: 'Owner',
    type: 'STRING',
    format: {},
}

const DEFAULT_MESSAGES_ALL_COLUMNS = [
    {
        name: '@MessageTime',
        title: 'Sent',
        type: 'DATETIME',
    },
    {
        name: '@MessageFromId',
        title: 'Message From',
        type: 'STRING',
    },
    {
        name: '@MessageToId',
        title: 'Message To',
        type: 'STRING',
    },
]

const DEFAULT_MESSAGES_PRIMARY_COLUMN_LIST = [
    {
        target: '@MessageTime',
        type: 'EQ',
        values: [],
        preset: 'ALL',
        join: 'AND',
    },
    {
        target: '@MessageFromId',
        type: 'EQ',
        values: [],
        preset: 'ALL',
        join: 'AND',
    },
]

const DEFAULT_ACTIVE_DISPACTH_CONDITIONS = {
    target: '@DispatchOwnerId',
    type: 'EQ',
    preset: 'ALL',
}

const PrimaryFilterComponent = ({
    viewData,
    environment,
    tab,
    query,
    isAddNew,
    queryView,
    allColumns,
    allColumnsLoading,
    listActiveFields,
    saveSettingDispatch,
    expandedAccordion,
    setExpandedAccordion,
    viewType,
}) => {
    const [t] = useTranslation('common')
    const [value, setValue] = useState('')
    const [primaryFilter, setPrimaryFilter] = useState([])
    const [mobileCondition, setMobileCondition] = useState([])

    const operators = useMemo(() => {
        return operatorOptions(t)
    }, [t])

    const dateValues = useMemo(() => {
        return datesValues(t)
    }, [t])

    const nonDateValues = useMemo(() => {
        return nonDatesValues(t)
    }, [t])

    const title = useMemo(() => t('common:view.applyThePrimaryFilter'), [t])

    const generatePrimaryFilterValue = () => {
        let nameTmp = ' '
        let tmpConditions = primaryFilter

        const tmp = tmpConditions
            .filter((item) => !isEmpty(item))
            .map((item) => {
                // let field = viewDataObj?.allColumns?.find((item2) => item2.name === item?.target)?.title
                let field = allColumns.find((item2) => item2.name === item?.target)?.title
                if (!field) {
                    field = item?.target
                }
                let ope = operatorMap[item?.type]
                if (!ope) {
                    ope = operators.find((item2) => item2.type === item?.type)?.name
                }
                if (!ope) {
                    ope = item?.type
                }
                let val = ''
                if (item?.preset) {
                    val = dateValues?.find((item2) => item2.preset === item.preset)?.name
                    if (!val) {
                        val = nonDateValues?.find((item2) => item2.preset === item.preset)?.name
                    }
                    if (!val) {
                        val = item.preset
                    }
                } else if (item?.values) {
                    if (Array.isArray(item.values) && item.values.length > 1) {
                        val = ' [ ' + item.values.join(' ; ') + ' ]'
                    } else {
                        val = item.values
                    }
                }
                return field + ' ' + ope + ' ' + val
            })

        if (tmp && tmp.length > 0) {
            let tmpFilter = tmp.filter((x) => x !== '')
            nameTmp = tmpFilter?.join(', ')
        }
        // if (nameTmp.length > 1) {
        //     nameTmp = nameTmp.substring(2)
        // }

        if (!nameTmp) {
            nameTmp = ' '
        }

        setValue(nameTmp)
    }

    const queryConditions = useMemo(() => {
        if (isEmpty(queryView)) return []
        if (!queryView.queries[0].filter) return []

        let conditions = queryView.queries[0].filter.conditions
        if (!isEmpty(conditions)) {
            if (viewType === WIZARD_VIEW_TYPES.ACTIVE_DISPATCHES) {
                if (conditions.length === 0) {
                    return [{}, DEFAULT_ACTIVE_DISPACTH_CONDITIONS]
                } else if (conditions.length === 1) {
                    return [{}, conditions[0]]
                }
                return conditions
            } else if (viewType === WIZARD_VIEW_TYPES.MESSAGES) {
                if (conditions.length === 0 || conditions.length === 3) {
                    return DEFAULT_MESSAGES_PRIMARY_COLUMN_LIST
                }
                return conditions
            }
            if (!conditions[1]) {
                conditions[1] = { preset: 'ALL', target: '@UserStatus', type: 'EQ' }
            } else {
                if (conditions[1].target === '@InProgress') {
                    conditions[1].target = '@UserStatus'
                }
                if (conditions[1].type === 'IN') {
                    conditions[1].type = 'EQ'
                }
            }
            if (conditions[1].target === ACTIVITY_RECEIVE_TIME_KEY) {
                return [...conditions].reverse()
            }

            if (allColumns?.length > 0) {
                let filteredConditions = conditions.filter((item) => {
                    return allColumns.some((item2) => item2.name === item?.target)
                })

                if (
                    viewType === WIZARD_VIEW_TYPES.MOBILE_UNITS &&
                    filteredConditions?.length === 0
                ) {
                    conditions = [
                        {
                            target: 'My_Datetime',
                            //target: '@StartTime',
                            type: 'EQ',
                            //preset: 'LAST30DAYS',
                            value: [],
                            preset: 'ALL',
                        },
                        {
                            target: 'Status',
                            type: 'EQ',
                            values: [],
                            preset: 'ALL',
                        },
                    ]
                } else if (viewType === WIZARD_VIEW_TYPES.FEED && filteredConditions?.length <= 1) {
                    filteredConditions = allColumns.filter((item) => {
                        return item.name === '@FeedDeviceId'
                    })
                    if (filteredConditions?.length === 1) {
                        conditions = [
                            {
                                target: '@FeedReceiveTime',
                                type: 'EQ',
                                value: [],
                                preset: 'TODAY',
                                join: 'AND',
                            },

                            {
                                target: '@FeedDeviceId',
                                type: 'EQ',
                                values: [],
                                preset: 'ALL',
                            },
                        ]
                    } else {
                        filteredConditions = conditions.filter((item) => {
                            return item.target === '@UserStatus'
                        })
                        if (filteredConditions?.length === 1) {
                            conditions = [
                                {
                                    target: '@FeedReceiveTime',
                                    type: 'EQ',
                                    value: [],
                                    preset: 'TODAY',
                                    join: 'AND',
                                },

                                {
                                    target: '@FeedStatus',
                                    type: 'EQ',
                                    values: [],
                                    preset: 'ALL',
                                },
                            ]
                        }
                    }

                    // 	{
                    // 		target: '@FeedDeviceId',
                    // 		type: 'EQ',
                    // 		values: [],
                    // 		preset: 'ALL',
                    // 	},
                    // ]
                } else if (viewType === WIZARD_VIEW_TYPES.FEED && filteredConditions?.length <= 1) {
                    filteredConditions = allColumns.filter((item) => {
                        return item.name === '@FeedDeviceId'
                    })
                    if (filteredConditions?.length === 1) {
                        conditions = [
                            {
                                target: '@FeedReceiveTime',
                                type: 'EQ',
                                value: [],
                                preset: 'TODAY',
                                join: 'AND',
                            },

                            {
                                target: '@FeedDeviceId',
                                type: 'EQ',
                                values: [],
                                preset: 'ALL',
                            },
                        ]
                    } else {
                        filteredConditions = conditions.filter((item) => {
                            return item.target === '@UserStatus'
                        })
                        if (filteredConditions?.length === 1) {
                            conditions = [
                                {
                                    target: '@FeedReceiveTime',
                                    type: 'EQ',
                                    value: [],
                                    preset: 'TODAY',
                                    join: 'AND',
                                },

                                {
                                    target: '@FeedStatus',
                                    type: 'EQ',
                                    values: [],
                                    preset: 'ALL',
                                },
                            ]
                        }
                    }

                    // 	{
                    // 		target: '@FeedDeviceId',
                    // 		type: 'EQ',
                    // 		values: [],
                    // 		preset: 'ALL',
                    // 	},
                    // ]
                }
            }
        } else {
            if (viewType === WIZARD_VIEW_TYPES.ACTIVE_DISPATCHES)
                return [{}, DEFAULT_ACTIVE_DISPACTH_CONDITIONS]
        }
        return conditions
    }, [queryView, allColumns, viewType])

    const columns = useMemo(() => {
        if (viewType === WIZARD_VIEW_TYPES.ACTIVE_DISPATCHES) {
            return [DEFAULT_ACTIVE_DISPACTH_PRIMARY_COLUMN]
        } else if (viewType === WIZARD_VIEW_TYPES.MESSAGES) {
            return DEFAULT_MESSAGES_ALL_COLUMNS
        }
        return !isEmpty(allColumns) && !isEmpty(listActiveFields)
            ? [...allColumns.filter((tmpItem) => listActiveFields?.includes(tmpItem?.name))]
            : environment.formColumnsChanged?.length
                ? environment.formColumnsChanged
                : viewData?.columns
                    ? [...viewData?.columns]
                    : []
    }, [viewType, allColumns, listActiveFields, environment.formColumnsChanged, viewData?.columns])

    useEffect(() => {
        if (viewType === WIZARD_VIEW_TYPES.PROJECT_FORM) {
            if (isAddNew || isEmpty(viewData)) {
                const primaryFilter = [
                    {
                        target: '@StartTime',
                        type: 'EQ',
                        preset: 'LAST30DAYS',
                        value: [],
                    },
                    { target: '@UserStatus', type: 'EQ', preset: 'ALL', values: [] },
                ]
                setPrimaryFilter(primaryFilter)
                return
            }
        }

        const primaryFilter = isEmpty(queryConditions) ? [] : [...queryConditions]
        setPrimaryFilter(primaryFilter)
    }, [viewType, viewData, queryConditions])

    useEffect(() => {
        let queries = queryView?.queries || []
        let conditions = queries && queries?.length > 0 ? queries[0]?.filter?.conditions : undefined
        if (allColumns?.length > 0 && conditions && viewType === WIZARD_VIEW_TYPES.MOBILE_UNITS) {
            setMobileCondition(
                conditions.filter((item) => {
                    return allColumns.some((item2) => item2.name !== item?.target)
                })
            )
        }
    }, [viewType, queryView, allColumns])

    useLayoutEffect(() => {
        let listMobilCondition = []
        if (viewType === WIZARD_VIEW_TYPES.MOBILE_UNITS) {
            for (let i = 0; i < mobileCondition?.length; i++) {
                let oTemp = primaryFilter.find((item) => item.target === mobileCondition[i].target)
                if (!oTemp) {
                    listMobilCondition.push(mobileCondition[i])
                }
            }
        }
        saveSettingDispatch({
            type: SETTING_TYPES.PRIMARY_FILTER,
            value: [...listMobilCondition, ...primaryFilter],
        })
    }, [primaryFilter, mobileCondition])

    useEffect(() => {
        if (allColumnsLoading || isEmpty(allColumns) || isEmpty(primaryFilter)) {
            setValue('')
            return
        }

        generatePrimaryFilterValue()
    }, [allColumnsLoading, allColumns, primaryFilter])

    return (
        <CollapseComponent
            title={title}
            value={value}
            footer={
                <Typography
                    variant="subtitle1"
                    style={{
                        color: 'gray',
                    }}
                >
                    {/* Select the data retrieved from the server. You can add additional client side filters
					later. */}
                </Typography>
            }
            expanded={expandedAccordion === title}
            setExpandedAccordion={setExpandedAccordion}
            content={
                !isEmpty(listActiveFields) && (
                    <DoformsConditionsBuilder
                        columns={columns}
                        environment={environment}
                        query={query}
                        tab={tab}
                        viewData={viewData}
                        //conditions={queryConditions}
                        setPrimaryFilter={setPrimaryFilter}
                        conditions={primaryFilter ? [...primaryFilter] : []}
                        // [{
                        //     target: '', type: 'LAST30DAYS', preset: ''
                        // }, { target: '', type: 'EQ', preset: '' }]}
                        iconsTheme={environment.iconsTheme}
                        isWizard={true}
                        onBuilderClosed={() => { }}
                    />
                )
            }
        />
    )
}

export const PrimaryFilterCollapse = memo(PrimaryFilterComponent, shouldPreventRender)
