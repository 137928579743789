import { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    DEFAULT_PRIMARY_FILTER_FEED,
    FEED_VIEW_TYPE,
    WIZARD_VIEW_TYPES,
    DISPATCH_ACTIVE_TYPE,
    DEFAULT_PRIMARY_FILTER_ACTIVE_DISPATCHES,
} from './ViewDialogUtils'
import { loadAllColumns } from 'components/data/dataServices'

import { VIEW } from './../../../../constants'
import { isEmpty } from 'lodash'
import {
    getAggregationModel,
    getRowGroupingModel,
    getSortModel,
    toViewWizardUserFiltersData,
} from 'utils/functions/helpers'
import { feedViewApi } from 'apis/disApi/viewWizard/feedViewApi'
import { mobileUnitsApi } from '../../../../apis/disApi/viewWizard/mobileUnitsViewApi'
import { activeDispatchesApi } from 'apis/disApi/viewWizard/activeDispatchesApi'
import { messagesViewApi } from 'apis/disApi/viewWizard/messagesViewApi'

export function useWizardQuery({
    initViewData,
    viewType,
    environment,
    isAddNew,
    initColumns,
    initQueryView,
    initSortModel,
    initRowGroupingModel,
    initAggregationModel,
    initExternalBgColorConditions,
    initRecords,
    initGridRows,
    initRecordsLoading,
    tab,
}) {
    const [viewData, setViewData] = useState(initViewData || {})
    const [loadingViewData, setLoadingViewData] = useState(false)
    const [allColumns, setAllColumns] = useState([])
    const [primaryFilters, setPrimaryFilters] = useState([])
    const [projectFormInfo, setProjectFormInfo] = useState({})
    const { activityModule } = useSelector((state) => state)
    const [allColumnsLoading, setAllColumnsLoading] = useState(false)
    const [queryView, setQueryView] = useState({})
    const [clientFilter, setClientFilter] = useState([])
    const [userClientFilters, setUserClientFilters] = useState([])

    const [sortModel, setSortModel] = useState([])
    const [rowGroupingModel, setRowGroupingModel] = useState([])
    const [aggregationModel, setAggregationModel] = useState([])
    const [externalBgColorConditions, setExternalBgColorConditions] = useState({})
    const [records, setRecords] = useState([])
    const [gridRows, setGridRows] = useState([])
    const [recordsLoading, setRecordsLoading] = useState(false)

    const {
        dataGrid: { columns },
    } = activityModule

    const [listActiveFields, setListActiveFields] = useState([])
    const [listDataGroups, setListDataGroups] = useState([])

    const currentColumns = useMemo(() => {
        if (isEmpty(projectFormInfo)) {
            return viewData?.columns
        } else {
            return (isEmpty(initColumns) ? viewData?.columns : initColumns) || []
        }
    }, [projectFormInfo, viewData?.columns, initColumns])

    useEffect(() => {
        const activeFields = currentColumns ? currentColumns.map((curItem) => curItem.name) : []

        setListActiveFields(activeFields)
    }, [currentColumns])

    async function getMobileUnitsViewData() {
        const result = await mobileUnitsApi.getViewData(environment.apiToken)
        return result?.data
    }

    async function getMobileUnitsViewAllColumns() {
        setAllColumnsLoading(true)
        const result = await mobileUnitsApi.getColumns(environment.apiToken)
        setAllColumnsLoading(false)
        return result?.data
    }

    async function getFeedViewData() {
        const result = await feedViewApi.getViewData(environment.apiToken)
        return result?.data
    }

    async function getFeedViewAllColumns() {
        setAllColumnsLoading(true)
        const result = await feedViewApi.getColumns(environment.apiToken)
        setAllColumnsLoading(false)
        return result?.data
    }

    async function getActiveDispatchesViewData() {
        const result = await activeDispatchesApi.getViewData(environment.apiToken)
        return result?.data
    }

    async function getActiveDispatchesAllColumns() {
        setAllColumnsLoading(true)
        const result = await activeDispatchesApi.getColumns(environment.apiToken)
        setAllColumnsLoading(false)
        return result?.data
    }

    async function getActiveDispatchesAllRecords(viewKey, filter) {
        setAllColumnsLoading(true)
        const result = await activeDispatchesApi.getAllRecords(
            viewKey,
            filter,
            environment.apiToken
        )
        setAllColumnsLoading(false)
        return result?.data?.records || []
    }

    function buildActiveDispatchesQueryView(initQueries) {
        const newQueries = initQueries.map((query) => {
            const newQuery = query
            if (isEmpty(query.filter)) {
                newQuery.filter = {
                    conditions: [],
                }
            }

            return newQuery
        })
        return { queries: newQueries }
    }

    async function getMessagesViewData() {
        const result = await messagesViewApi.getViewData(environment.apiToken)
        return result?.data
    }

    async function getMessagesViewAllColumns() {
        setAllColumnsLoading(true)
        const result = await messagesViewApi.getColumns(environment.apiToken)
        setAllColumnsLoading(false)
        return result?.data
    }

    async function getMessagesViewAllRecords(viewKey, filter) {
        setAllColumnsLoading(true)
        const result = await messagesViewApi.getAllRecords(viewKey, filter, environment.apiToken)
        setAllColumnsLoading(false)
        return result?.data?.records || []
    }

    async function getProjectFormAllColumns(proFrmInfo) {
        if (!proFrmInfo || !proFrmInfo.formKey) {
            return []
        }
        const result = await loadAllColumns(proFrmInfo.formKey, environment.apiToken)
        return result?.data || []
    }

    async function getMobileUnitsAllRecords(oViewData, oQuery) {
        setAllColumnsLoading(true)
        let oViewSession = true
        const result = await mobileUnitsApi.getRecords(
            oViewData?.key,
            oViewSession,
            oQuery?.filter,
            environment.apiToken
        )
        setAllColumnsLoading(false)
        return result?.data?.records || []
    }

    async function getFeedAllRecords(oViewData, oQuery) {
        setAllColumnsLoading(true)
        let oViewSession = true
        const result = await feedViewApi.getRecords(
            oViewData?.key,
            oViewSession,
            oQuery?.filter,
            environment.apiToken
        )
        setAllColumnsLoading(false)
        return result?.data?.records || []
    }

    function getProjectformInfo(viewData, isAddNew) {
        const projectKey = viewData?.projectKey
        const formKey = viewData?.formKey
        let project = environment.projects.find((item) => item.key === projectKey)
        if (!project && environment.lookups) {
            if (environment.lookups.length > 0) {
                project = environment.lookups?.find((item) => item.key === projectKey)
            } else {
                if (environment.lookups.key === projectKey) {
                    project = environment.lookups
                }
            }
        }
        const projectName = project?.name || ''
        const form = environment.forms.find((item) => item.key === formKey)
        const formName = form?.name || ''
        return {
            projectKey,
            projectName,
            formKey,
            formName,
        }
    }

    const getProjectformInfoCB = useCallback(
        (viewData, isAddNew) => {
            return getProjectformInfo(viewData, isAddNew)
        },
        [environment.projects, environment.lookups, environment.forms]
    )

    function getClientFilter(oViewData) {
        const oViewDataClientFilter = oViewData?.clientFilter
            ? JSON.parse(oViewData?.clientFilter)
            : []
        const wizardFilters = {
            viewClientFilters: [],
            userClientFilters: [],
        }
        if (!oViewDataClientFilter) return wizardFilters

        return oViewDataClientFilter.reduce((prev, current) => {
            if (current.dataGroupKey) {
                prev.userClientFilters.push(current)
            } else {
                prev.viewClientFilters.push(current)
            }

            return prev
        }, wizardFilters)
    }

    function getWizardSortModel(oViewData, oAllColumns) {
        return getSortModel(oViewData?.columns, oViewData?.sorts)
    }

    function getWizardRowGroupingModel(oColumns) {
        return getRowGroupingModel(oColumns)
    }

    function getWizardAggregationModel(oColumns) {
        return getAggregationModel(oColumns)
    }

    function getColorConditions(oViewData) {
        const res =
            oViewData?.colorConditions === undefined || isEmpty(oViewData?.colorConditions)
                ? {}
                : JSON.parse(oViewData?.colorConditions)
        return res
    }

    const viewTypeValue = useMemo(() => {
        if (isAddNew) {
            return viewType
        }

        return tab === VIEW.VIEW_TAB_VIEWS || tab === VIEW.TAB_TILE_BUILDER
            ? VIEW.VIEW_TAB_VIEWS
            : viewType
    }, [viewType, isAddNew, tab])

    useEffect(() => {
        ;(async () => {
            try {
                setLoadingViewData(true)
                let wizardViewData = {}
                let wizardViewColumns = []
                let proFrmInfo = {}
                let wizardQueryView = {}
                let wizardClientFilter = []
                let wizardSortModel = []
                let wizardRowGroupingModel = []
                let wizardAggregationModel = []
                let wizardExternalBgColorConditions = {}
                let wizardAllRecords = []
                let wizardViewType = viewTypeValue
                switch (wizardViewType) {
                    case WIZARD_VIEW_TYPES.MOBILE_UNITS: {
                        wizardViewData = await getMobileUnitsViewData()
                        wizardViewColumns = await getMobileUnitsViewAllColumns()
                        wizardQueryView = { queries: wizardViewData.queries }
                        wizardClientFilter = getClientFilter(wizardViewData)
                        wizardSortModel = getWizardSortModel(wizardViewData, wizardViewColumns)
                        wizardRowGroupingModel = getWizardRowGroupingModel(wizardViewColumns)
                        wizardAggregationModel = getWizardAggregationModel(wizardViewColumns)
                        wizardExternalBgColorConditions = getColorConditions(wizardViewData)
                        wizardAllRecords = await getMobileUnitsAllRecords(
                            wizardViewData,
                            wizardViewData?.queries[0]
                        )
                        break
                    }
                    case WIZARD_VIEW_TYPES.FEED: {
                        wizardViewData = await getFeedViewData()
                        wizardViewColumns = await getFeedViewAllColumns()
                        wizardQueryView = { queries: wizardViewData.queries }
                        wizardClientFilter = getClientFilter(wizardViewData)
                        wizardSortModel = getWizardSortModel(wizardViewData, wizardViewColumns)
                        wizardRowGroupingModel = getWizardRowGroupingModel(wizardViewColumns)
                        wizardAggregationModel = getWizardAggregationModel(wizardViewColumns)
                        wizardExternalBgColorConditions = getColorConditions(wizardViewData)
                        wizardAllRecords = await getFeedAllRecords(
                            wizardViewData,
                            wizardViewData?.queries[0]
                        )
                        break
                    }
                    case WIZARD_VIEW_TYPES.ACTIVE_DISPATCHES: {
                        wizardViewData = await getActiveDispatchesViewData()
                        wizardViewColumns = await getActiveDispatchesAllColumns()
                        wizardQueryView = buildActiveDispatchesQueryView(wizardViewData.queries)
                        wizardClientFilter = getClientFilter(wizardViewData)
                        wizardSortModel = getWizardSortModel(wizardViewData, wizardViewColumns)
                        wizardRowGroupingModel = getWizardRowGroupingModel(wizardViewColumns)
                        wizardAggregationModel = getWizardAggregationModel(wizardViewColumns)
                        wizardExternalBgColorConditions = getColorConditions(wizardViewData)
                        wizardAllRecords = await getActiveDispatchesAllRecords(
                            wizardViewData?.key,
                            {}
                        )
                        break
                    }
                    case WIZARD_VIEW_TYPES.MESSAGES: {
                        wizardViewData = await getMessagesViewData()
                        wizardViewColumns = await getMessagesViewAllColumns()
                        wizardQueryView = { queries: wizardViewData.queries }
                        wizardClientFilter = getClientFilter(wizardViewData)
                        wizardSortModel = getWizardSortModel(wizardViewData, wizardViewColumns)
                        wizardRowGroupingModel = getWizardRowGroupingModel(wizardViewColumns)
                        wizardAggregationModel = getWizardAggregationModel(wizardViewColumns)
                        wizardExternalBgColorConditions = getColorConditions(wizardViewData)
                        wizardAllRecords = await getMessagesViewAllRecords(
                            wizardViewData?.key,
                            wizardViewData?.queries[0]
                        )
                        break
                    }
                    default: {
                        wizardViewData = initViewData
                        proFrmInfo = getProjectformInfoCB(initViewData, isAddNew)
                        //-------------------------------- use for tab view or edit in tileBuilder
                        if (wizardViewType === VIEW.VIEW_TAB_VIEWS) {
                            wizardViewColumns = initColumns
                            wizardRowGroupingModel = initRowGroupingModel
                            wizardAggregationModel = initAggregationModel
                        }
                        //--------------------------------
                        wizardQueryView = initQueryView
                        wizardClientFilter = getClientFilter(wizardViewData)
                        wizardSortModel = initSortModel
                        wizardExternalBgColorConditions = initExternalBgColorConditions
                        wizardAllRecords = initRecords
                    }
                }
                setViewData(wizardViewData)
                if (wizardViewData?.type === FEED_VIEW_TYPE) {
                    setPrimaryFilters(DEFAULT_PRIMARY_FILTER_FEED)
                } else if (wizardViewData?.type === DISPATCH_ACTIVE_TYPE) {
                    setPrimaryFilters(DEFAULT_PRIMARY_FILTER_ACTIVE_DISPATCHES)
                } else {
                    setPrimaryFilters(wizardViewColumns)
                }
                setProjectFormInfo(proFrmInfo)
                if (isEmpty(wizardQueryView)) {
                    wizardQueryView = wizardViewData
                }
                setQueryView(wizardQueryView)
                setClientFilter(wizardClientFilter.viewClientFilters || [])
                setUserClientFilters(
                    toViewWizardUserFiltersData(wizardClientFilter.userClientFilters || {})
                )
                setSortModel(isEmpty(wizardSortModel) ? [] : wizardSortModel)
                setRowGroupingModel(isEmpty(wizardRowGroupingModel) ? [] : wizardRowGroupingModel)
                setAggregationModel(isEmpty(wizardAggregationModel) ? [] : wizardAggregationModel)
                setExternalBgColorConditions(wizardExternalBgColorConditions)
                setRecords(wizardAllRecords)
                setGridRows(wizardAllRecords)
                setLoadingViewData(false)
            } catch (err) {
                console.log(err)
                throw err
            }
        })()
    }, [viewTypeValue, getProjectformInfoCB])

    useEffect(() => {
        ;(async () => {
            try {
                setAllColumnsLoading(true)
                switch (viewType) {
                    case WIZARD_VIEW_TYPES.PROJECT_FORM:
                        if (!projectFormInfo || !projectFormInfo.formKey) break
                        const projectColumnsResponse = await loadAllColumns(
                            projectFormInfo.formKey,
                            environment.apiToken
                        )
                        setAllColumns([...projectColumnsResponse.data])
                        setPrimaryFilters([...projectColumnsResponse.data])
                        let wizardRowGroupingModel = []
                        let wizardAggregationModel = []
                        wizardRowGroupingModel = getRowGroupingModel([
                            ...projectColumnsResponse.data,
                        ])
                        wizardAggregationModel = getAggregationModel(columns)
                        setRowGroupingModel(
                            isEmpty(wizardRowGroupingModel) ? [] : wizardRowGroupingModel
                        )
                        setAggregationModel(
                            isEmpty(wizardAggregationModel) ? [] : wizardAggregationModel
                        )
                        break

                    case WIZARD_VIEW_TYPES.FEED:
                        const feedColumnsResponse = await getFeedViewAllColumns()
                        setAllColumns([...feedColumnsResponse])
                        setPrimaryFilters([...feedColumnsResponse])
                        break
                    case WIZARD_VIEW_TYPES.MOBILE_UNITS:
                        const mobileUnitColumnsResponse = await getMobileUnitsViewAllColumns()
                        setAllColumns([...mobileUnitColumnsResponse])
                        setPrimaryFilters([...mobileUnitColumnsResponse])
                        break
                    case WIZARD_VIEW_TYPES.ACTIVE_DISPATCHES:
                        const activeDispatchesColumnResponse = await getActiveDispatchesAllColumns()
                        setAllColumns([...activeDispatchesColumnResponse])
                        setPrimaryFilters([...activeDispatchesColumnResponse])
                        break
                    case WIZARD_VIEW_TYPES.MESSAGES:
                        const messagesColumnResponse = await getMessagesViewAllColumns()
                        setAllColumns([...messagesColumnResponse])
                        setPrimaryFilters([...messagesColumnResponse])
                        break
                    default:
                        setAllColumns([])
                        setPrimaryFilters([])
                }

                setAllColumnsLoading(false)
            } catch (err) {
                setAllColumns([])
                setPrimaryFilters([])
                setAllColumnsLoading(false)
            }
        })()
    }, [viewType, projectFormInfo, environment.apiToken])

    const resetData = (resetProjectFormInfo = false) => {
        if (resetProjectFormInfo) {
            setProjectFormInfo({})
        }
        setViewData({})
        setListActiveFields([])
        setAllColumns([])
        setPrimaryFilters([])
    }
    return {
        viewData,
        loadingViewData,
        allColumns,
        projectFormInfo,
        allColumnsLoading,
        currentColumns,
        listActiveFields,
        queryView,
        clientFilter,
        userClientFilters,
        sortModel,
        rowGroupingModel,
        aggregationModel,
        externalBgColorConditions,
        records,
        gridRows,
        recordsLoading,
        setProjectFormInfo,
        resetData,
        setListActiveFields,
        updateMobileUnitView: mobileUnitsApi.updateViewData,
        insertMobileUnitView: mobileUnitsApi.insertViewData,
        listDataGroups,
        primaryFilters,
    }
}
