import { useEffect, useState, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { makeStyles } from '@mui/styles'
import {
	DataGridPremium,
	useGridApiRef,
	useKeepGroupedColumnsHidden,
	GridToolbarContainer,
	GridColumnMenu,
} from '@mui/x-data-grid-premium'
import { IconButton, Tooltip, Box, Typography, Grid, TextField, Stack } from '@mui/material'
import { ClearOutlined, RefreshOutlined } from '@mui/icons-material'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isEmpty } from 'lodash'

import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import enUS from 'date-fns/locale/en-US'

import TileWrapper from '../../components/TileWrapper'
import FeedSettingsDialog from './FeedSettingsDialog'
import tileApi from 'apis/disApi/tileApi'

import { IconThemeProvider } from 'custom-components/context/IconThemesContext'
import { isJson, logErrorMessage } from '../../../../../utils/functions/helpers'
import { useTileDashboard } from '../../dashboard/Dashboard'
import { tileKeys } from '../../hooks/useTileQuery'
import useFeedQuery from '../../hooks/useFeedQuery'
import { toDayjs } from 'utils/functions/doformsDateUtil'

const SPLIT_REGEX = '_SPLIT*_'
const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
	},

	formSidePanel: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		height: '100%',
		backgroundColor: '#ffffff',
	},

	clearButton: {
		padding: '16px 0 !important',
	},
}))

function dateTimeFormat(date, offset, format) {
	if (date === null || date === '') {
		return ''
	}
	let newDate = new Date(date)

	let m = moment(newDate)
	const fullDateFormat = 'MM/DD/YYYY HH:mm'
	const isToday = m.isSame(moment(), 'day')
	let formatUse = format ? format : isToday ? 'hh:mm' : fullDateFormat
	if (offset != null) m.utcOffset(offset)
	return m.isValid() ? m.format(formatUse) : ''
}

function createDefaultDate(isStart) {
	const fullDateFormat = 'MM/DD/YYYY HH:mm'
	const currentDay = moment(new Date(), fullDateFormat)
	const result = isStart ? currentDay.startOf('day') : currentDay.endOf('day')
	return moment(result, fullDateFormat)
}

function FeedTile(props) {
	const { tile } = props

	const [t] = useTranslation()

	const { environment } = useSelector((state) => state)
	const iconTheme = environment.theme.icons
	const { id: dashboardKey } = useParams()

	const queryClient = useQueryClient()
	const updateTileMutation = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})
	const tileRef = useRef(null)
	const apiRef = useGridApiRef()

	const settings = useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])

	const { tileWidth, connectedDatagridKey: connectedMobileUnitKey } = settings

	// data states
	const { isFeedLoading, allFeed, refetch } = useFeedQuery({ tile })
	const { selectedMobileUnitForFeedTile } = useTileDashboard()
	const [settingsOpen, setSettingsOpen] = useState(false)

	const [startDate, setStartDate] = useState(() => createDefaultDate(true))
	const [endDate, setEndDate] = useState(() => createDefaultDate(false))

	const filteredData = useMemo(() => {
		if (isEmpty(allFeed)) return []
		const allMobileUnitNumber = {}
		Object.assign(
			allMobileUnitNumber,
			...allFeed
				.filter((feed) => feed.device.number)
				.map((feedWithNumber) => ({
					[feedWithNumber.device.key]: {
						number: feedWithNumber.device.number,
						name: feedWithNumber.device.name,
					},
				}))
		)

		const result = allFeed.map((feed) => {
			const mobileUnitNumber = feed.device.number || allMobileUnitNumber[feed.device?.key]?.number
			const mobileUnitName = feed.device.name || allMobileUnitNumber[feed.device?.key]?.name
			return {
				...feed,
				mobileUnitNumber,
				displayMobileUnitName: `${mobileUnitName}${SPLIT_REGEX}${mobileUnitNumber}`,
			}
		})

		const filterValue = selectedMobileUnitForFeedTile[connectedMobileUnitKey]?.number
		const filterByMobileKey = filterValue
			? result.filter((item) => item.mobileUnitNumber === filterValue)
			: result

		const startDateMoment = moment(startDate)
		const endDateMoment = moment(endDate)
		if (!startDateMoment.isValid() || !endDateMoment.isValid()) return filterByMobileKey

		return filterByMobileKey.filter((item) => {
			const itemDate = moment(item.date)
			if (!itemDate.isValid()) return false

			return itemDate.isSameOrAfter(startDateMoment) && itemDate.isSameOrBefore(endDateMoment)
		})
	}, [allFeed, connectedMobileUnitKey, startDate, endDate, selectedMobileUnitForFeedTile])

	const onSearchClear = () => {
		setStartDate(createDefaultDate(true))
		setEndDate(createDefaultDate())
	}

	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	const handleSubmitSetting = async (data) => {
		try {
			const { connectedDatagridKey } = data
			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: {
					settings: JSON.stringify({
						...settings,
						connectedDatagridKey,
					}),
				},
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		} finally {
			handleCloseDialog()
		}
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({
				...settings,
				tileWidth: width,
			})

			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const columns = [
		{
			field: 'date',
			headerName: 'Date',
			width: 200,
			valueGetter: (value) => {
				if (!value) return ''
				return new Date(value)
			},
			valueFormatter: (value) => {
				if (!value) {
					return ''
				}
				const useOneTimezone = environment?.userCurrent?.time?.useOneTimezone
				// If useOneTimezone is false, get value from default row value, because it format already
				if (useOneTimezone === false) {
					return dateTimeFormat(value, null)
				} else {
					const currentUserTimeZone = environment.userCurrent.time.timezone.isDst
						? (environment.userCurrent.time.timezone.offset || 0) - 60
						: environment.userCurrent.time.timezone.offset || 0
					return !value ? '' : dateTimeFormat(value, currentUserTimeZone)
				}
			},
		},
		{
			field: 'description',
			headerName: 'Description',
			width: 500,
			renderCell: (value) => {
				if (value.rowNode.type === 'group') return ''

				const row = value.row
				if (!row) return ''

				const projectName = row.project?.name
				const formName = row.form?.name
				const fullDateFormat = 'MM/DD/YYYY HH:mm'
				const date = dateTimeFormat(row.date, null, fullDateFormat)

				const TooltipTile = (
					<div
						style={{
							display: 'grid',
						}}
					>
						{formName && projectName && <span>{`${formName} (${projectName})`}</span>}
						<span>{date}</span>
						<span>{row.status}</span>
					</div>
				)
				return (
					<Tooltip title={TooltipTile} placement="bottom-start">
						<span className="table-cell-trucate">{row.description}</span>
					</Tooltip>
				)
			},
		},
		{
			field: 'displayMobileUnitName',
			headerName: 'Device',
			renderCell: (value) => {
				if (value.rowNode.type === 'group') {
					const splitedValue = value.value?.split(SPLIT_REGEX) || []
					if (isEmpty(splitedValue)) return <span>{value.value}</span>
					return (
						<Tooltip title={splitedValue[1]} placement="bottom-start">
							<span style={{ fontWeight: 'bold' }} className="table-cell-trucate">
								{splitedValue[0]}
							</span>
						</Tooltip>
					)
				}

				return <span>{value.row?.name || ''}</span>
			},
		},
	]

	const initialState = useKeepGroupedColumnsHidden({
		apiRef,
		initialState: {
			columns: {
				columnVisibilityModel: {
					// Hide the column "description" used for leaves
					date: false,
				},
			},
			rowGrouping: {
				model: ['displayMobileUnitName'],
			},
		},
	})

	return (
		<IconThemeProvider values={iconTheme}>
			<TileWrapper
				title={tile?.i}
				onSettingClick={handleOpenDialog}
				ref={tileRef}
				isExpandDialogBtn
			>
				<FeedSettingsDialog
					tileElementWidth={tileRef?.current?.clientWidth}
					defaultTileWidth={tileWidth}
					tile={tile}
					settings={settings}
					isSubmitting={updateTileMutation.isLoading}
					open={settingsOpen}
					onClose={handleCloseDialog}
					onSubmit={handleSubmitSetting}
					onResizeTileWidth={handleResizeTileWidth}
				/>

				<Box
					sx={{
						position: 'absolute',
						left: 0,
						right: 0,
						top: 30,
						bottom: 0,
						background: '#fff',

						'& .MuiDataGrid-selectedRowCount': {
							opacity: '0 !important',
						},
					}}
				>
					<DataGridPremium
						sx={{
							'& .MuiDataGrid-columnHeaders': {
								// minHeight: '40px !important',
								// maxHeight: '40px !important',
								// lineHeight: '40px !important',
							},
							'& .MuiDataGrid-footerContainer': {
								minHeight: '40px !important',
							},
						}}
						slots={{
							toolbar: CustomToolbar,
							footer: CustomFooterComponent,
							columnMenu: CustomColumnMenu,
						}}
						slotProps={{
							toolbar: {
								startDate,
								setStartDate,
								endDate,
								setEndDate,
								refetch,
								onSearchClear,
							},
							footer: {
								rowCount: filteredData?.length,
							},
						}}
						columns={columns}
						rows={filteredData}
						loading={isFeedLoading}
						apiRef={apiRef}
						groupingColDef={{ leafField: 'date' }}
						// expanded all groups
						defaultGroupingExpansionDepth={-1}
						rowHeight={25}
						columnHeaderHeight={28}
						getRowId={(row) => row.key}
						initialState={initialState}
					/>
				</Box>
			</TileWrapper>
		</IconThemeProvider>
	)
}

function CustomToolbar(props) {
	const { refetch, startDate, setStartDate, endDate, setEndDate, onSearchClear } = props

	const [t] = useTranslation()
	const classes = useStyles()

	const [locale, setLocale] = useState(enUS)

	const displayStartDate = useMemo(() => {
		return toDayjs(startDate)
	}, [startDate])

	const displayEndDate = useMemo(() => {
		return toDayjs(endDate)
	}, [endDate])

	useEffect(() => {
		const importLocaleFile = async () => {
			const localeToSet = await import(
				`date-fns/locale/${t('common:languages.dateFnsLocale')}/index.js`
			)
			setLocale(localeToSet.default)
		}

		if (locale.code !== t('common:languages.dateFnsLocale')) {
			importLocaleFile()
		}
	}, [t('common:languages.dateFnsLocale')])

	return (
		<GridToolbarContainer sx={{ height: '44px' }}>
			<Stack direction={'row'} spacing={1}>
				<Grid container spacing={0}>
					<Grid
						xs
						sm
						md
						lg
						xl
						style={{
							marginRight: '5px',
						}}
					>
						<LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
							<DateTimePicker
								id="map-date"
								label={'From'}
								value={displayStartDate}
								onChange={(newValue) => setStartDate(newValue.format('MM/DD/YYYY HH:mm:ss'))}
								slotProps={{
									textField: {
										InputProps: {
											size: "small",
											variant: "outlined"
										}
									}
								}}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid xs sm md lg xl>
						<LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
							<DateTimePicker
								id="map-date"
								label={'To'}
								value={displayEndDate}
								onChange={(newValue) => setEndDate(newValue.format('MM/DD/YYYY HH:mm:ss'))}
								slotProps={{
									textField: {
										InputProps: {
											size: "small",
											variant: "outlined"
										}
									}
								}}
							/>
						</LocalizationProvider>
					</Grid>
				</Grid>
				<Tooltip title={`Clear filter`} arrow placement="bottom-start" disableInteractive>
					<span>
						<IconButton
							aria-label="refresh"
							size="small"
							className={classes.clearButton}
							sx={{ p: 2 }}
							onClick={onSearchClear}
						>
							<ClearOutlined fontSize="inherit" className={classes.icon} />
						</IconButton>
					</span>
				</Tooltip>
				<Tooltip
					title={`${t('tooltip.refresh')}`}
					arrow
					placement="bottom-start"
					disableInteractive
				>
					<span>
						<IconButton
							aria-label="refresh"
							size="small"
							className={classes.clearButton}
							sx={{ p: 2 }}
							onClick={refetch}
						>
							<RefreshOutlined fontSize="inherit" className={classes.icon} />
						</IconButton>
					</span>
				</Tooltip>
			</Stack>
		</GridToolbarContainer>
	)
}

function CustomFooterComponent(props) {
	const { rowCount } = props
	return (
		<Box
			sx={{
				p: 1,
				minHeight: '40px !important',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'end',
				padding: '0 16px !important',
			}}
		>
			<div></div>
			<Typography
				style={{
					color: 'rgba(0, 0, 0, 0.87)',
					fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
					fontWeight: '400',
					fontSize: '0.875rem',
					lineHeight: '1.43',
					letterSpacing: '0.01071em',
				}}
			>
				Total rows: {rowCount}
			</Typography>
		</Box>
	)
}

function CustomColumnMenu(props) {
	return (
		<GridColumnMenu
			{...props}
			slots={{
				// Hide `columnMenuGroupingItem, columnMenuColumnsItem`
				columnMenuGroupingItem: null,
				columnMenuColumnsItem: null,
			}}
		/>
	)
}

export default FeedTile
