import { cloneDeep, isEqual, isEmpty } from 'lodash'
import {
    DEFAULT_LOGIC_OPERATOR,
    convertFromDisplayKey,
    convertToDisplayKey,
    sortDataGroupFilterKeyFn,
} from 'utils/functions/helpers'
export const WIZARD_VIEW_TYPES = {
    PROJECT_FORM: 'Project/Form',
    FEED: 'Activity',
    MOBILE_UNITS: 'Mobile units',
    ACTIVE_DISPATCHES: 'Active dispatches',
    MESSAGES: 'Messages',
}

export const PROJECT_FORM_VIEW_TYPE = 'RECORD'
export const MOBILE_UNIT_VIEW_TYPE = 'DEVICE'
export const FEED_VIEW_TYPE = 'FEED'
export const DISPATCH_ACTIVE_TYPE = 'DISPATCH_ACTIVE'
export const MESSAGES_VIEW_TYPE = 'MESSAGE'
export const DEFAULT_PRIMARY_FILTER_FEED = [
    {
        name: '@FeedReceiveTime',
        title: 'Date',
        type: 'DATETIME',
        format: {
            date: 'MM/DD/YYYY h:mm:ss A',
        },
    },
    {
        name: '@FeedDeviceId',
        title: 'Mobile number',
        type: 'STRING',
        format: {},
    },
]

export const DEFAULT_PRIMARY_FILTER_ACTIVE_DISPATCHES = [
    {
        name: '@FeedDeviceId',
        title: 'Mobile number',
        type: 'STRING',
        format: {},
    },
]

export const DATA_OBJ_ACTION_TYPES = {
    INIT_DATA: 'initdata',
    ADD: 'add',
    UPDATE: 'update',
    DELETE: 'delete',
}

export const DATAGROUP_FILTER_ACTION_TYPE = {
    INIT_DATA: 'initdata',
    ADD_GROUP: 'addGroup',
    UPDATE_GROUP: 'updateGroup',
    // DELETE_GROUP: 'deleteGroup',
    ADD_FILTER: 'addFilter',
    UPDATE_FILTER: 'updateFilter',
    DELETE_FILTER: 'deleteFilter',
    CHANGE_LOGIC_OPERATOR: 'changeLogicOperator',
}

export const SETTING_TYPES = {
    PRIMARY_FILTER: 'primaryFilter',
    ADDITIONAL_FILTER: 'additionalFilter',
    USER_SPECIFIC_FILTER: 'userSpecificFilter',
    SORT_DATA: 'sortData',
    GROUP_THE_DATA: 'groupTheData',
    CALCULATIONS: 'calculations',
    COLOR_CONDITIONS: 'colorConditions',
    CHART: 'chart',
    SAVE_VIEW: 'saveView',
}

export function shouldPreventRender(prevProps, nextProps) {
    return isEqual(prevProps, nextProps)
}

export function dataObjectReducer(state, action) {
    switch (action.type) {
        case DATA_OBJ_ACTION_TYPES.INIT_DATA:
            return action.initData
        case DATA_OBJ_ACTION_TYPES.ADD: {
            const currentState = cloneDeep(state)
            currentState.push(action.target.defaultItem)
            return currentState
        }
        case DATA_OBJ_ACTION_TYPES.UPDATE: {
            const currentState = cloneDeep(state)
            // Replace item at index using native splice
            currentState.splice(action.target.index, 1, action.target.value)

            // also replace logic oprator for the first item depend on the second item logic oprator
            const newLogicOperator = action.target.value?.logicOperator
            if (action.target.index === 1 && newLogicOperator) {
                const newFirstItem = cloneDeep(state[0])
                newFirstItem.logicOperator = newLogicOperator
                currentState.splice(0, 1, newFirstItem)
            }

            return currentState
        }
        case DATA_OBJ_ACTION_TYPES.DELETE: {
            const currentState = cloneDeep(state)
            currentState.splice(action.target.index, 1)
            // special case for additonal filter to set the logicOperator as 'and' if this array.length is 1
            if (currentState.length === 1 && currentState[0].logicOperator) {
                currentState[0].logicOperator = DEFAULT_LOGIC_OPERATOR
            }
            return currentState
        }
        default:
            return { ...state }
    }
}

export function settingDataReducer(state, action) {
    switch (action.type) {
        case SETTING_TYPES.PRIMARY_FILTER:
            return {
                ...state,
                [action.type]: action.value,
            }
        case SETTING_TYPES.ADDITIONAL_FILTER:
            return {
                ...state,
                [action.type]: action.value,
            }
        case SETTING_TYPES.SORT_DATA:
            return {
                ...state,
                [action.type]: action.value,
            }
        case SETTING_TYPES.GROUP_THE_DATA:
            return {
                ...state,
                [action.type]: action.value,
            }
        case SETTING_TYPES.CALCULATIONS:
            return {
                ...state,
                [action.type]: action.value,
            }
        case SETTING_TYPES.CHART:
            return {
                ...state,
                [action.type]: action.value,
            }
        case SETTING_TYPES.COLOR_CONDITIONS:
            return {
                ...state,
                [action.type]: action.value,
            }
        case SETTING_TYPES.USER_SPECIFIC_FILTER:
            return {
                ...state,
                [action.type]: action.value,
            }
        default:
            return { ...state }
    }
}

export function dataGroupFilterReducer(state, action) {
    switch (action.type) {
        case DATAGROUP_FILTER_ACTION_TYPE.INIT_DATA:
            return action.initData
        case DATAGROUP_FILTER_ACTION_TYPE.ADD_GROUP: {
            const currentState = cloneDeep(state)
            let length = Object.keys(currentState).length
            const targetDataGroupKey = action.payload?.dataGroupKey
            const value = action.payload?.value
            const displayKey = convertToDisplayKey(length, targetDataGroupKey)
            const currentValue = currentState[displayKey]
            if (isEmpty(currentValue)) {
                currentState[displayKey] = [value]
            } else {
                currentState[displayKey] = [...currentValue, value]
            }

            return currentState
        }
        case DATAGROUP_FILTER_ACTION_TYPE.UPDATE_GROUP: {
            const currentState = cloneDeep(state)
            const oldKey = action.payload.oldGroupKey
            const newKey = action.payload.newGroupKey
            const currentIndex = action.payload.index

            const displayOldKey = convertToDisplayKey(currentIndex, oldKey)
            const oldKeyValue = currentState[displayOldKey]
            delete currentState[displayOldKey]

            const displayNewKey = convertToDisplayKey(currentIndex, newKey)
            const newKeyValue = oldKeyValue?.map((filter) => ({ ...filter, dataGroupKey: newKey }))
            currentState[displayNewKey] = newKeyValue

            // Replace item at index using native splice
            return currentState
        }
        case DATAGROUP_FILTER_ACTION_TYPE.ADD_FILTER: {
            const currentState = cloneDeep(state)
            const targetDataGroupKey = action.payload?.dataGroupKey
            const targetDataGroupIndex = action.payload?.dataGroupIndex
            const targetDisplayKey = convertToDisplayKey(targetDataGroupIndex, targetDataGroupKey)
            const value = action.payload?.value
            const currentValue = currentState[targetDisplayKey]
            if (isEmpty(currentValue)) {
                currentState[targetDisplayKey] = [value]
            } else {
                currentState[targetDisplayKey] = [...currentValue, value]
            }
            return currentState
        }
        case DATAGROUP_FILTER_ACTION_TYPE.UPDATE_FILTER: {
            const currentState = cloneDeep(state)
            const targetDataGroupKey = action.payload?.dataGroupKey
            const targetDataGroupIndex = action.payload?.dataGroupIndex
            const targetDisplayKey = convertToDisplayKey(targetDataGroupIndex, targetDataGroupKey)
            const value = action.payload?.value
            const currentValue = currentState[targetDisplayKey]
            // Replace item at index using native splice
            currentValue.splice(action.payload.index, 1, value)
            currentState[targetDisplayKey] = currentValue
            return currentState
        }
        case DATAGROUP_FILTER_ACTION_TYPE.DELETE_FILTER: {
            const currentState = cloneDeep(state)
            const targetDataGroupKey = action.payload?.dataGroupKey
            const targetDataGroupIndex = action.payload?.dataGroupIndex
            const targetDisplayKey = convertToDisplayKey(targetDataGroupIndex, targetDataGroupKey)
            const currentValue = currentState[targetDisplayKey]

            currentValue.splice(action.payload.index, 1)
            if (isEmpty(currentValue)) {
                delete currentState[targetDisplayKey]
                const stateWithNewIndex = {}
                const sortedDisplayKeys = Object.keys(currentState).sort(sortDataGroupFilterKeyFn)
                for (let newIndex = 0; newIndex < sortedDisplayKeys.length; newIndex++) {
                    const displayKey = sortedDisplayKeys[newIndex]
                    const currentStateValue = currentState[displayKey]
                    const currentDataGroupKey = convertFromDisplayKey(displayKey)
                    const newDisplayKey = convertToDisplayKey(newIndex, currentDataGroupKey)
                    stateWithNewIndex[newDisplayKey] = currentStateValue
                }
                return stateWithNewIndex
            } else {
                // if only 1 filter, set logic operator to AND
                if (currentValue.length === 1) {
                    currentValue[0].logicOperator = DEFAULT_LOGIC_OPERATOR
                }
                currentState[targetDisplayKey] = currentValue
            }

            return currentState
        }
        case DATAGROUP_FILTER_ACTION_TYPE.CHANGE_LOGIC_OPERATOR: {
            const currentState = cloneDeep(state)
            const targetDataGroupKey = action.payload?.dataGroupKey
            const targetDataGroupIndex = action.payload?.dataGroupIndex
            const targetDisplayKey = convertToDisplayKey(targetDataGroupIndex, targetDataGroupKey)
            const value = action.payload?.value
            const currentValue = currentState[targetDisplayKey]

            // Replace logic operator for all filters of group
            const newValue = currentValue.map((filter) => ({ ...filter, logicOperator: value }))
            currentState[targetDisplayKey] = newValue
            return currentState
        }
        default:
            return { ...state }
    }
}
